import React, {useEffect} from 'react';
import Modal from '@mui/material/Modal';
import { LoopCircleLoading } from 'react-loadingg';
import { inject, observer } from "mobx-react";
import Box from '@mui/material/Box';
import { DateTime } from "luxon";
import * as cloneDeep from 'lodash/cloneDeep';
import {DEFAULT_EMAIL} from "../assets/globalConstants";
import {pickLanguageWord} from '../utils/pickLanguageWord.js';
import { constructMyDataForDeltio, issueDeltio, calculateVats } from "../utils/print-service.js";
import { v4 as uuidv4 } from 'uuid';

// Translations
import _useTranslate from '../hooks/_useTranslate';

import * as uniq from 'lodash/uniq';

const style = {
  position: 'absolute',
  width: {
    xs: '100%',
    sm: '80%', 
    md: 300,  
  },
  height: 270,
  top: {xs: 60, sm: '50%'},
  left: {xs: 0, sm: '50%'},
  transform: {sm: 'translate(-50%, -50%)'},   
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 4,
  boxShadow: 24,
 
};

const LoadingOrderModal = ({visible, whenSent, whenError, total, feathersStore})=> {

  let common = _useTranslate(feathersStore.language, "common");
  const cartItems = JSON.parse(localStorage.getItem('fdwa_cart'));
  const masterLanguage = feathersStore.settings.masterLanguage;
  
  useEffect(() => {
   visible && total && sendOrder();
  }, [visible, total]);

  //----------> LOYALTY

  const addNewRewards = async() => {//applied at the end of sendOrder
    if(feathersStore?.user?.email !== DEFAULT_EMAIL){ 
      let loyaltyRewardsClone = cloneDeep(feathersStore.user?.loyaltyRewards || []);
      for(let reward of loyaltyRewardsClone){
        Object.assign(reward, {applied: true});
        return reward;
      };
     
      const appliedCampaigns = loyaltyRewardsClone?.map(r => r.code);

      const now = DateTime.now().setZone("Europe/Athens");
      const _coupons = await feathersStore.coupons();
      const _campaigns = _coupons?.filter(coupon =>  coupon?.active && (coupon?.completion > 0))
     
      for(let campaign of  _campaigns){
        if(!appliedCampaigns?.includes(campaign.code)){   
          let valid = false;   
          if(campaign?.endDate){
            if(DateTime.fromISO(campaign.endDate).endOf('day') > now)valid = true;
          }else{
            if(DateTime.fromISO(campaign.startDate).plus({days : campaign.duration}).endOf('day') > now)valid = true;
          }
          if(valid){
            const userOrders = await feathersStore.ordersPerUser();
            const turnover = userOrders
            ?.filter(order => DateTime.fromMillis(order.createdAt) > DateTime.fromISO(campaign.startDate))
              .map(order => order?.total || 0)
              .reduce((a, b) => +a + +b, 0);
            if(turnover >= campaign?.completion){
              let value = campaign.reward;
              if(campaign.type === "Percent"){
                value = campaign.completion * campaign.reward / 100
              }
              Object.assign(campaign, {applied: false, value});
              loyaltyRewardsClone.push(campaign);
            }           
          }
        }
      }     
      await feathersStore._patchUser(feathersStore?.user._id, {loyaltyRewards: loyaltyRewardsClone})
    }
  }

  //-------------->

  const sendOrder = async() => { 
    try{
      if(feathersStore.settings?.MY_DATA)feathersStore.setOrderItem({activeUid: uuidv4()}); //used for mydata payment

      if(feathersStore.tableId ){
        feathersStore.setOrderItem({ordering: true})

        //----> subOrder START
        if((feathersStore.settings?.masterLanguage !== 'el') || feathersStore.settings?.deltioAuto){
          let cartItemClone = cloneDeep(feathersStore.orderItem.items);
          const subOrderTotal = cartItemClone
            ?.map(item => item.product_free ? 0 : +item.product_totalPrice)
            .reduce((a,b) => a + b)
            .toFixed(2); 
          const oldDeltioCounter = await feathersStore.getDeltioCounter(); 
          const subOrderNumericId = +oldDeltioCounter.sequence_value + 1;
          cartItemClone.map(item =>  Object.assign(item, {subOrderId: subOrderNumericId}));
          let subOrder = {
            numericId: subOrderNumericId,
            issuer: feathersStore.user._id,
            issuerUsername: feathersStore.user?.username,
            subOrderTotal: parse_fix(subOrderTotal),
            createdAt: new Date().getTime(),
            tableId: feathersStore.tableId,
            tableName: feathersStore.orderItem._id,
            orderId: cartItemClone?._id,
            subOrderItems: cartItemClone
          };
          Object.assign(subOrder,  calculateVats(cartItemClone))
          await feathersStore.patchCounters("deltio", {sequence_value: parseInt(subOrderNumericId)});

          if(feathersStore.settings?.deltioAuto){

            const response = await constructMyDataForDeltio(subOrder, 'orderTaking.component.ts', '513', subOrder.numericId);          
            if(response?.qrcode){
              Object.assign(subOrder, {response})
              const printPayload = await issueDeltio(subOrder, response); 
              Object.assign(subOrder, {printPayload});           
              let subOrders = cloneDeep(feathersStore.orderItem?.subOrders || []);
              subOrders.push(subOrder);
              feathersStore.setOrderItem({subOrders});
            }else{
              //---> Rollback ---> Let it continue and the dashboard will take care
                   
                cartItemClone.map(item =>  Object.assign(item, {subOrderId: ""}));
                await feathersStore.patchCounters("deltio", {sequence_value: parseInt(oldDeltioCounter.sequence_value)});            
              
              //-------> 
            }
          }
          if(feathersStore.settings?.masterLanguage !== 'el'){
            let subOrders = feathersStore.selectedTable.order?.subOrders || [];
            subOrders.push(subOrder);
            feathersStore.setOrderItem({subOrders});
          }
          feathersStore.setOrderItem(cartItemClone)
        }
        //<------subOrder END  

        const data = await feathersStore._patchTable(feathersStore.tableId, feathersStore.orderItem);
        await printTableOrder(feathersStore.orderItem._id, feathersStore.orderItem.bookingDate, data.order.numericId)
      } 
      else await feathersStore.createOrder(feathersStore.orderItem); 
      emptyCart(); 
      await addNewRewards();
      feathersStore.setCartLength(0);
      whenSent();
    }catch(error){  
      console.log(error)
      alert(
        `${common.connectionIssue}`
      )
      whenError();
    }    
  }  

  const checkLanguage = printerLang => {

    //----> In PDA there will be examined the feathersStore.settings.masterLanguage and the language 
    // of the printer only and not the feathersStore.language (it is only for UI).

    return printerLang ? printerLang : masterLanguage;
  }

  const checkAr = (printerLang) => {
    if(printerLang === "ar")return true;
    else if(masterLanguage === "ar")return true;
    else return false;
  }

  const constructAttributesList = (attributesArray, printer, printerLang, thermalFonts) => {
    let attributesList = "";
    for(let val of attributesArray){
      attributesList += 
      (printer.nonEpos ? `<text-line ${checkAr(printerLang) ? 'lang = "ar"' : ""} ${thermalFonts ? 'size="1:0"' : ''}>` : "") +
      val.name + " " + (val?.quantity > 1 ? ("x" + val.quantity) : "") + (printer.nonEpos ? "</text-line>" : "&#10;")
    }
    return attributesList;
  }

  const printTableOrder = async(tableName, bookingDate, numericId) => {
    const thermalFonts = feathersStore.settings?.thermalFonts || false;
    let finalOrderItems = [...cartItems];
    const date = new Date(); 
    const day = date.getDate().toString().padStart(2, "0"); 
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().padStart(2, "0");
    const localDate = day + '/' + month + '/' + year
    for(let printer of feathersStore?.settings?.printers.filter(p => p.enabled)){
      const printerLang = checkLanguage(printer?.useLanguage && printer?.language ? printer.language : "");
      let itemsList = "";     
      let printerItems = finalOrderItems.filter(o => o.product_printers.map(prn => prn.name).includes(printer.name));
      if(printerItems?.length > 0){
        for (let item of printerItems){
          let extraList = "";
          let ingredientList = "";
          let attributeList = "";
          const weightedString = item.product_weightedQuantity > 0 ? `${item.product_weightedQuantity * 1000} gr` : ""
          const productTotalPrice = item.product_free ? "0.00" : `${parse_fix(item.product_totalPrice)}`;

          if(item?.features)for(let feature of item.features){extraList += 
            (printer.nonEpos ? `<text-line ${checkAr(printerLang) ? 'lang = "ar"' : ""} ${thermalFonts ? 'size="1:0"' : ''}>` : "") +
            feathersStore.translateFeature(feature, printerLang) +       
            (printer.nonEpos ? "</text-line>" : "&#10;")};

          if(item?.attributes){
    
            let attributesMap = new Map();
            for(let attribute of item.attributes){
              if(!attributesMap.has(feathersStore.translateAttributeTitle(attribute, printerLang))){
                attributesMap.set( feathersStore.translateAttributeTitle(attribute, printerLang), 
                  new Array({
                    name:  feathersStore.translateAttributeName(attribute, printerLang),
                    quantity: attribute?.quantity || 0
                  }));
              }else{
                let valueArray = attributesMap.get( feathersStore.translateAttributeTitle(attribute, printerLang));
                valueArray.push({
                  name:  feathersStore.translateAttributeName(attribute, printerLang),
                  quantity: attribute?.quantity || 0
                });
                attributesMap.set( feathersStore.translateAttributeTitle(attribute, printerLang), valueArray);
              }
            }

            for(let entry of attributesMap.entries()){
              attributeList +=   
                (printer.nonEpos ? "<text-line></text-line>" : "&#10;") +
                (printer.nonEpos ? `<bold><text-line ${checkAr(printerLang) ? 'lang = "ar"' : ""} ${thermalFonts ? 'size="1:0"' : ''}>` : "") +
                entry[0] + (printer.nonEpos ? "</text-line></bold>" : "&#10;") +
                constructAttributesList(entry[1], printer, printerLang, thermalFonts)              
            }        
          }; 

          if(item?.ingredients)for(let ingredient of item.ingredients){ingredientList += 
            (printer.nonEpos ? `<text-line ${checkAr(printerLang) ? 'lang = "ar"' : ""} ${thermalFonts ? 'size="1:0"' : ''}>` : "") +
            feathersStore.translateFeature(ingredient, printerLang) + 
            (printer.nonEpos ? "</text-line>" : "&#10;")};
          
          
         if(printer.nonEpos){
            itemsList = itemsList + 
            `<text-line></text-line><bold><text-line ${checkAr(printerLang) ? 'lang = "ar"' : ""} ${thermalFonts ? 'size="1:0"' : ''}>${item.product_quantity} X ${feathersStore.translateProductName(item, printerLang)} ${feathersStore.translateFeature(item?.product_size, printerLang)  || ""} ${weightedString} ${productTotalPrice}<set-symbol-cp>€</set-symbol-cp></text-line></bold>` +
              (feathersStore.settings?.printDescription && item?.product_shortDescription ? `<text-line ${checkAr(printerLang) ? 'lang = "ar"' : ""} ${thermalFonts ? 'size="1:0"' : ''}>${feathersStore.translateShortDescription(item, printerLang)}</text-line>` : "") +
              (extraList ? `<text-line ${checkAr(printerLang) ? 'lang = "ar"' : ""} ${thermalFonts ? 'size="1:0"' : ''}>${extraList}</text-line>` : "") +
              (attributeList ? `<text-line ${checkAr(printerLang) ? 'lang = "ar"' : ""} ${thermalFonts ? 'size="1:0"' : ''}>${attributeList}</text-line>` : "") + 
              (ingredientList ? `<text-line ${checkAr(printerLang) ? 'lang = "ar"' : ""} ${thermalFonts ? 'size="1:0"' : ''}>${pickLanguageWord(printerLang, "without")}: ${ingredientList}</text-line>` : "") +
              (item.product_comments ? `<text-line ${checkAr(printerLang) ? 'lang = "ar"' : ""} ${thermalFonts ? 'size="1:0"' : ''}>${pickLanguageWord(printerLang, "comments")}: ${item.product_comments}</text-line>` : "")
          }else{
            itemsList = itemsList + `<text lang="en" em="true">${item.product_quantity} X ${feathersStore.translateProductName(item, printerLang)} ${feathersStore.translateFeature(item?.product_size, printerLang) || ""} ${weightedString} (${productTotalPrice}€)&#10;</text>` +
              (feathersStore.settings?.printDescription && item?.product_shortDescription ? `<text lang="en" em="false" smooth="true">${feathersStore.translateShortDescription(item, printerLang)}&#10;</text>` : "") +
              (extraList ? `<text lang="en" em="false" smooth="true">${extraList}&#10;</text>` : "") +
              (attributeList ? `<text lang="en" em="false" smooth="true">${attributeList}</text>` : "") + 
              (ingredientList ? `<text lang="en" em="false" smooth="true">${pickLanguageWord(printerLang, "without")}: ${ingredientList}&#10;</text>` : "") +
              (item.product_comments ? `<text lang="en" em="false" smooth="true">${pickLanguageWord(printerLang, "comments")}: ${item.product_comments}&#10;</text>` : "")
          } 
        }   

        let req =   
          '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">' +
            '<s:Body>' +
              '<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">' +
              '<text align = "center" />' +
              constructCompanyTitle() +
              '<feed unit="24"/>' + 
              `${thermalFonts ? '<text width="2"></text>' : ''}` +
              `<text lang="en" em="true">${pickLanguageWord(printerLang, "newOrder")} QRCODE&#10;</text>` +
              `<text lang="en" em="true">${pickLanguageWord(printerLang, "num")}: ${numericId}&#10;</text>` +
              '<feed unit="24"/>' +
              `<text lang="en" em="true">>${pickLanguageWord(printerLang, "table")}: ${tableName}&#10;</text>` +
              `<text lang="en" em="true">${pickLanguageWord(printerLang, "dateCut")}: ${localDate} ${date.toLocaleTimeString('en-GB')}&#10;</text>` +
              `${thermalFonts ? '<text width="1"></text>' : ''}` +
              `<text lang="en">------------------------------------------&#10;</text>` +
              `${thermalFonts ? '<text width="2"></text>' : ''}` +
              '<text align = "left" />' +
                itemsList + 
              '<feed unit="24"/>' +         
              '<text align = "center" />' +
              `<text lang="en" em="false" smooth="true">${pickLanguageWord(printerLang, "total")}: ${parse_fix(total)}€&#10;</text>` +
              `${thermalFonts ? '<text width="1"></text>' : ''}` +
              `<text lang="en">------------------------------------------&#10;</text>` +
              `${thermalFonts ? '<text width="2"></text>' : ''}` +
              `<text lang="en">qrcode&#10;</text>` +           
              '<feed unit="24"/>' +                
              '<cut/>' +
              '</epos-print>' +
            '</s:Body>' +
          '</s:Envelope>';
        let payload = {ip: printer.ip, devid: printer.devId, req};

        if(printer?.nonEpos){
         
          req =  
            '<?xml version="1.0" encoding="UTF-8"?>' +
            '<document>' +
              '<set-cp/>' +
              '<align mode="center">' +
                constructCompanyTitleNonEpos() +             
              '<line-feed />' +                             
              '<bold>' +
                `<text-line ${checkAr(printerLang) ? 'lang = "ar"' : ""} ${thermalFonts ? 'size="1:0"' : ''}>${pickLanguageWord(printerLang, "newOrder")} QRCODE: ${numericId}</text-line>` +
                `<text-line ${checkAr(printerLang) ? 'lang = "ar"' : ""} ${thermalFonts ? 'size="1:0"' : ''}>${pickLanguageWord(printerLang, "num")}: ${numericId}</text-line>` +
                '<line-feed />' +
                `<text-line ${checkAr(printerLang) ? 'lang = "ar"' : ""} ${thermalFonts ? 'size="1:0"' : ''}>${pickLanguageWord(printerLang, "table")}: ${tableName}</text-line>` +
                `<text-line ${checkAr(printerLang) ? 'lang = "ar"' : ""}  ${thermalFonts ? 'size="1:0"' : ''}>${pickLanguageWord(printerLang, "dateCut")}: ${localDate} ${date.toLocaleTimeString('en-GB')}&#10;</text-line>` + 
              '</bold>' +
              `<text lang="en">------------------------------------------&#10;</text>` +
              '</align>' +
              '<align mode = "left">' +            
                itemsList + 
              '</align>' +
              '<line-feed />' +       
              '<align mode="center">' +
                `<bold><text-line ${checkAr(printerLang) ? 'lang = "ar"' : ""} ${thermalFonts ? 'size="1:0"' : ''}>${pickLanguageWord(printerLang, "total")}: ${parse_fix(total)}<set-symbol-cp>€</set-symbol-cp></text-line></bold>` +
                `<text-line>------------------------------------------</text-line>` +
                `<text-line ${thermalFonts ? 'size="1:0"' : ''}>qrcode</text-line>` +
              '</align>' +
              '<line-feed />' +                     
              '<paper-cut />' +
            '</document>' ;
           
          Object.assign(payload, {nonEpos: true, req})
          
        }

        try{
          await feathersStore.createLocalPrint(payload);   
          const uniquesArray = uniq(finalOrderItems.map(p => p.product_id));
          for(let id of uniquesArray){      
          const sum = finalOrderItems
            .filter(o => o.product_id === id)
            .map(p =>  (p?.product_weightedQuantity > 0 ? p.product_quantity * p.product_weightedQuantity : p.product_quantity))
            .reduce((a,b) => +a + +b, 0);
          const item = finalOrderItems.find(p => p.product_id === id)
          if(item.product_handleStock){
            const newStock = Math.round((item.product_stock - sum + Number.EPSILON) * 1000) / 1000;
            await feathersStore.patchProduct(id,  { stock : newStock })
          }       
        }                  
        }catch(err){console.log('Could not Print!', err)};    
      }
    }
  }

  const constructCompanyTitle = () => {
    return(
    `<text dw="true" dh="true" />`+
    `<text lang="en" em="true">${feathersStore.settings.companyName}&#10;</text>` +
    '<feed unit="24"/>' + 
    `<text dw="false" dh="false" />`+
    `<text lang="en" em="true">${feathersStore.settings.address}&#10;</text>` + 
    `<text lang="en" em="true">${feathersStore.settings.occupation}&#10;</text>` 
    )
  }

  const constructCompanyTitleNonEpos = () => {
    return(
      `<bold><text-line size="1:1">${feathersStore.settings.companyName}</text-line></bold>` +
      '<line-feed />' +
      '<bold>' +
        `<text-line>${feathersStore.settings.address}</text-line>` + 
        `<text-line>${feathersStore.settings.occupation}</text-line>` +
      '</bold>'
    )
  }

  const parse_fix = price => {
    return price ? parseFloat(price).toFixed(2) : 0;
  } 


  const emptyCart = () => {
    const emptyItem = {
      'customerId': "",
      'bookingDate': "",
      'status': '',
      'items': [],
      'paymentMethod':"",
      'total': 0,
      'processed': false,
      'latitude': 0,
      'longitude': 0,
      'city': 1,
      'street': "",
      'streetNumber': "",
      'apartment': "",
      'postcode': "",
      'addressComments':""
    }
    localStorage.setItem("fdwa_cart", JSON.stringify([]));
    feathersStore.setOrderItem(emptyItem);
  }

  return (
    <section>
      <Modal 
        open={visible}        
        aria-labelledby="login-modal"
        aria-describedby="login-or-register"
      >
        <Box sx={style}>
          <div className="body-container">
            <h3>{common.sendOrder}</h3>
            <div>{common.pleaseWait}</div>
            <div className="modalRegisterFormContainer">
                <LoopCircleLoading />
            </div>
          </div>
        </Box>
      </Modal>
    </section>
  );
}

export default inject('feathersStore')(observer(LoadingOrderModal));
